<div class="d-flex flex-column">
  <div class="d-flex flex-column justify-content-between page-content">
    <section id="version-content" class="container">
      <div class="table-responsive">
        <table class="table table-bordered table-striped">
          <tbody>
            <tr>
              <th scope="row">Version</th>
              <td id="version">{{ version }}</td>
            </tr>
            <tr>
              <th scope="row">Version date</th>
              <td id="version-date">2024-10-25 14:06:33 EDT</td>
            </tr>
            <tr>
              <th scope="row">Environment name</th>
              <td id="environment-name">{{ environmentName }}</td>
            </tr>
            <tr>
              <th scope="row">Build number</th>
              <td id="build-number">3</td>
            </tr>
            <tr>
              <th scope="row">Git branch</th>
              <td id="git-branch">release_moonshiners_2024-10-28</td>
            </tr>
            <tr>
              <th scope="row">Git commit hash</th>
              <td id="git-commit">8eea29d90d315a95a62a9e96777456e5749c1a08</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  </div>
</div>
